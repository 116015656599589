<template>
    <article class="noticia-el">
        <div class="imagen" :style="`background:url(${data.img.url}) center/cover no-repeat`"></div>
        <div class="texto">
            <div>
                <h4 class="fecha">{{data.fecha.full | parsedate}}</h4>
                <h2>{{data.titulo}}</h2>
            </div>
            <a href="" class="link">Leer más</a>
            <router-link class="enlace-cover" :to="$t('links.saladeprensa')+'/'+data.slug"></router-link>
        </div>
    </article>
</template>


<script>

import moment from 'moment';

    export default{
        name : 'noticia',
        props : ['data'],
        filters :{
            parsedate(value){
                return moment(value).format("DD-MM-YYYY")
            }
        }
    }
</script>